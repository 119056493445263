import axios from 'axios';
import _ from 'lodash';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
function requestLogin(creds) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds
  };
}

function receiveLogin(user) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    user: user
  };
}
function receiveLogout(user) {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
    user: user
  }
}

function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  }
}

function logoutError(message) {
    return {
        type: LOGOUT_FAILURE,
        isFetching: false,
        isAuthenticated: false,
        message
    };
}
export function authHeader() {

    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.apiAccessToken) {
        return { 'Authorization': 'Bearer ' + user.apiAccessToken };
    } else {
        return {};
    }
}
export function authError(err) {
    let action = {};
    if (err && err.response && err.response.status == 401){
        localStorage.removeItem('user');
        localStorage.removeItem('apiAccessToken');
        action = loginError(err.message);
    }
    return action;
}
export function getUserFromLocalStorage() {
    let currentUser;
    try{
        currentUser = JSON.parse(localStorage.getItem('user'));
    }catch (e) {
        currentUser = {};
    }
    return currentUser;
}
export function loginUser(creds) {

  return dispatch => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin(creds));

    return axios.post('/api/auth/login', creds, {headers: {'Content-Type': 'application/json'}})
      .then((response) => {
          const user = response.data;
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('apiAccessToken', user.apiAccessToken);
          // Dispatch the success action
          dispatch(receiveLogin(user))
      }).catch((err) => {
          dispatch(loginError(_.get(err, 'response.data.error', err)));
      });
  }
}
export function logoutUser() {
    return dispatch => {
        return axios.post('/api/auth/logout', {},{headers: authHeader()}).then((response) => {
            localStorage.removeItem('user');
            localStorage.removeItem('apiAccessToken');
            dispatch(receiveLogout());
        }).catch((err) => {
            dispatch(loginError(_.get(err, 'response.data.error', err)));
        });
    };
}
export function registerUser(creds) {

  return dispatch => {
    if(creds.password != creds.password2){
        dispatch(loginError( "password not match"));
    }
    dispatch(requestLogin(creds));

    return axios.post('/api/auth/register', creds, {headers: {'Content-Type': 'application/json'}})
      .then((response) => {
          const user = response.data;
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('apiAccessToken', user.apiAccessToken);
          // Dispatch the success action
          dispatch(receiveLogin(user))
      }).catch((err) => {
          dispatch(loginError(err.message));
      });
  }
}
export function verifyFacebookToken(response) {
    return dispatch => {
        axios.post('/api/auth/facebook', {access_token: response.accessToken, appName:'betPool'},{
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(r => {
            const user = r.data;
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('apiAccessToken', user.apiAccessToken);
            // Dispatch the success action
            dispatch(receiveLogin(user))
        }).catch((err) => {
            dispatch(loginError(err.message));
        });
    }
}

export function verifyGoogleToken(response) {
    return dispatch => {
       // const tokenBlob = new Blob([JSON.stringify({access_token: response.accessToken}, null, 2)], {type : 'application/json'});
       // const options = {
       //     method: 'POST',
       //     body: tokenBlob,
       //     mode: 'cors',
       //     cache: 'default'
       // };
        axios.post('/api/auth/google',{access_token: response.accessToken, appName:'betPool'},{
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(r => {
            const user = r.data;
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('apiAccessToken', user.apiAccessToken);
            dispatch(receiveLogin(user))
        }).catch((err) => {
          dispatch(loginError(err.message));
        });
    }
}

export function registerWithFacebookToken(response){
    return dispatch => {
           axios.post('/api/auth/register/facebook',{access_token: response.accessToken, appName:'betPool'},{
                   headers: {
                       'Content-Type': 'application/json',
                   }
               }).then(r => {
               const user = r.data;
               localStorage.setItem('user', JSON.stringify(user));
               localStorage.setItem('apiAccessToken', user.apiAccessToken);
               dispatch(receiveLogin(user))
           }).catch((err) => {
             dispatch(loginError(err.message));
           });
       }
}


export function registerWithGoogleToken(response){
    return dispatch => {
           axios.post('/api/auth/register/google',{access_token: response.accessToken, appName:'betPool'},{
                   headers: {
                       'Content-Type': 'application/json',
                   }
               }).then(r => {
               const user = r.data;
               localStorage.setItem('user', JSON.stringify(user));
               localStorage.setItem('apiAccessToken', user.apiAccessToken);
               dispatch(receiveLogin(user))
           }).catch((err) => {
             dispatch(loginError(err.message));
           });
       }
}
